<template>
	<CustomModal custom-header custom-footer class="progress-bar-modal">
		<template #header>
			<div class="modal-header">
				<h6 class="modal-title">{{ $t("ImportFile.Improting") }}</h6>
				<CButtonClose @click="hideModal">-</CButtonClose>
			</div>
		</template>

		<template #content>
			<CProgress
				:value="done"
				:max="total"
				color="success"
				animated
				show-value
				:show-percentage="true"
				class="mx-auto progress-size"
			/>
		</template>

		<template #footer>
			<span></span>
		</template>
	</CustomModal>
</template>

<script>
import { createNamespacedHelpers } from "vuex"
const { mapState } = createNamespacedHelpers("serviceAppointment")
import { get } from "lodash-es"

export default {
	name: "ProgressBarModal",

	computed: {
		...mapState(["importStatus"]),

		done() {
			return get(this.importStatus, "done") || 0
		},

		total() {
			return get(this.importStatus, "total") || 100
		},
	},

	watch: {},

	methods: {
		get,

		hideModal() {
			this.$emit("hide-modal")
		},
	},
}
</script>
<style lang="scss" scoped>
.progress-bar-modal {
	/deep/ .modal-header {
		border-bottom: none;
	}
	/deep/ .modal-footer {
		border-top: none;
	}
	/deep/ .modal-body {
		padding-bottom: 2rem;
	}
	.progress-size {
		width: 80%;
		height: 12px;
	}
}
</style>
